import { Pipe, PipeTransform } from '@angular/core';
import { FactType } from '@app/users/fact.models';

@Pipe({
	name: 'factTypeIcon',
	standalone: true
})
export class FactTypeIconPipe implements PipeTransform {
	transform (type: any): string {
		switch (type) {
			case FactType.Text:
				return 'fas fa-text color-icon';
			case FactType.Date:
				return 'fas fa-calendar-days color-icon';
			case FactType.Options:
				return 'fas fa-list color-icon';
			case FactType.Boolean:
				return 'fas fa-square-check color-icon';
			default:
				return 'fas fa-folder color-folder';
		}
	}
}